import { FC, useEffect, useState } from 'react';

import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import RichText from '@components/RichText/RichText';
import { useContent, useLayoutData } from '@sitecore/common';
import { CookieWallRendering } from '@sitecore/types/CookieWall';
import { AlertDialog, Button, Stack, TextLink } from '@sparky';

const COOKIE_VERSIONS = {
  QUBIT: '2.0.0',
  PRIVACY: '1',
} as const;

const CookieWall: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const { f } = useContent<CookieWallRendering>();
  const { route } = useLayoutData();
  const hideCookieWall = Boolean(route?.fields?.hideCookieWall?.value);

  const handleSetCookie = (accept: boolean) => {
    const qubitValue = accept ? 'Accepted' : 'Declined';
    const privacyValue = accept ? 'true' : 'false';
    Cookies.set('qubitconsent', qubitValue, { expires: 365 });
    Cookies.set('qubitconsent_version', COOKIE_VERSIONS.QUBIT, { expires: 365 });
    Cookies.set('privacyConsent', privacyValue, { expires: 365 });
    Cookies.set('privacyConsent_type', 'explicit', { expires: 365 });
    Cookies.set('privacyConsent_version', COOKIE_VERSIONS.PRIVACY, { expires: 365 });
  };

  useEffect(() => {
    const checkCookiesAndAttachListeners = () => {
      const cookiesExists =
        Cookies.get('qubitconsent') && Cookies.get('privacyConsent') && Cookies.get('privacyConsent_type');
      const cookiesVersionMisMatch =
        Cookies.get('qubitconsent_version') !== COOKIE_VERSIONS.QUBIT ||
        Cookies.get('privacyConsent_version') !== COOKIE_VERSIONS.PRIVACY;

      if ((!cookiesExists || cookiesVersionMisMatch) && !hideCookieWall) {
        setIsOpen(true);
      }

      const handleClick = (event: Event) => {
        event.preventDefault();
        setIsOpen(true);
      };

      // Find all anchor elements that have '#cookiepermissionmodal' in their href attribute
      const elements = Array.from(document.querySelectorAll('a[href*="#cookiepermissionmodal"]')) as HTMLElement[];
      elements.forEach(element => {
        element.addEventListener('click', handleClick);
      });

      // Cleanup event listener on component unmount
      return () => {
        elements.forEach(element => {
          element.removeEventListener('click', handleClick);
        });
      };
    };

    const cleanup = checkCookiesAndAttachListeners();
    router.events?.on('routeChangeComplete', checkCookiesAndAttachListeners);

    return () => {
      cleanup();
      router.events?.off('routeChangeComplete', checkCookiesAndAttachListeners);
    };
  }, [router]);

  const footer = (
    <Stack direction={{ initial: 'column', md: 'row' }} gap="5">
      <AlertDialogPrimitive.Action asChild>
        <Button onClick={() => handleSetCookie(true)}>{f('yesButtonText.value')}</Button>
      </AlertDialogPrimitive.Action>
      <AlertDialogPrimitive.Cancel asChild>
        <Button onClick={() => handleSetCookie(false)}>{f('noButtonText.value')}</Button>
      </AlertDialogPrimitive.Cancel>
    </Stack>
  );

  return (
    <AlertDialog
      disableBackgroundInteraction={false}
      isOpen={isOpen}
      setOpen={setIsOpen}
      title={f('title.value')}
      footer={footer}>
      <RichText
        html={f('description.value')}
        replacements={{
          a: props => <TextLink {...props} />,
        }}
      />
    </AlertDialog>
  );
};

export default CookieWall;
